.vault-card {
	background-color: #d0dfdb;
	color: #1d2329;
	display: flex;
	margin-bottom: 40px;
	padding-top: 25px;
	padding-bottom: 15px;
	width: 1351px;
}
.card-description {
	padding-left: 15px;
	padding-right: 25px;
}
.vault-card .title {
	background-color: #112438;
	border-radius: 7px;
	color: #ffffff;
	margin: auto;
	padding: 5px;
	text-align: center;
	width: 170px;
}
.vault-card h2 {
	font-size: 29px;
	font-weight: 800;
	line-height: 37px;
	margin-top: 60px;
	text-align: center;
}
.vault-card p {
	font-size: 11px;
	font-weight: 500;
	line-height: 14px;
	margin-bottom: 40px;
}
.vault-card .card-content {
	display: flex;
	justify-content: space-between;
	margin-bottom: 15px;
}
.vault-card .card-content span:first-child {
	color: #474c57;
	font-size: 13px;
	font-weight: 500;
	line-height: 19px;
}

.vault-card .card-content span:last-child {
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	text-align: right;
}
