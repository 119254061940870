.product-card {
    border-radius: 15px 15px 0px 0px;
}

.product-card-divider {
    border-top: 1px solid #FFF;
    opacity: 0.9;
}

.product-title {
    font-family: 'Gilroy-Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 31px;
    text-transform: capitalize;
    padding-top: 20px;
    padding-bottom: 9px;
}

.product-sub-title {
    font-family: 'Gilroy-Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 31px;
    margin-bottom: 17px;
}

.product-card-padding-x {
    padding-left: 50px;
    padding-right: 50px;
}

.product-card-upper {
    position: relative;
    border-radius: 15px 15px 0px 0px;
    color: #FFFFFF;
    background: linear-gradient(252.02deg, #519BF6 21.1%, #0062DB 78.9%), linear-gradient(180deg, #519BF6 -45.25%, #FFFFFF 176.28%), #519BF6;
}

.product-card-bg {
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
}

.product-apy {
    color: var(--accent-color-2);
    align-items: baseline;
    gap: 10px;
}

.product-apy-value {
    font-family: 'Gilroy-Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 54px;
}

.product-apy-description {
    font-family: 'Gilroy-Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 35px;
}

.product-card-details {
    gap: 40px;
}

.product-card-detail-label {
    font-family: 'Gilroy-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    margin-bottom: 11px;
}

.product-card-detail-value {
    font-family: 'Gilroy-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 31px;
    text-transform: uppercase;
}

.product-card-detail-image {
    width: 35px;
    border: 1px solid #FFF;
    border-radius: 50%;
}

.product-deposit-input {
    width: 350px;
    height: 44px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #3D7FE1;
    border: 2px solid rgba(255, 255, 255, 0.4);
    font-family: 'Gilroy-Bold';
    font-weight: 400;
    font-size: 18px;
    line-height: 0px;
    color: #FFFFFF;
    padding: 0 10px;
}

.product-deposit-input::placeholder {
    color: #FFFFFF;
}

.product-card-footer {
    padding-top: 15px;
    padding-bottom: 15px;
    background: linear-gradient(0deg, #519BF6 -98.42%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
    border: 1px solid #519BF6;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 15px 15px;
}

.product-card-footer-heading {
    font-family: 'Gilroy-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 31px;
    padding-top: 10px;
}

.product-deposit-container {
    position: relative;
    z-index: 251;
}

.product-deposit-button {
    padding: 0 28px;
    background: radial-gradient(50% 68.47% at 50% 50%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%), #FCD12E;
    border: 1px solid #FFCC4D;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    height: 44px;
    line-height: 44px;
    font-family: 'Gilroy-Medium';
    font-size: 18px;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.05);
    z-index: 996;
}

.product-deposit-title {
    font-family: 'Gilroy-Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 31px;
}

.product-deposit-label {
    font-family: 'Gilroy-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 12px;
}

.product-benifits-title {
    font-family: 'Gilroy-Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    background: linear-gradient(180deg, #519BF6 0%, #0062DB 100%), linear-gradient(90deg, #1E48FF 0%, #001C99 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.product-benifits-highlight {
    font-family: 'Gilroy-Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 35px;
    color: rgba(0, 0, 0, 0.8);
}

.product-benifits-description {
    font-family: 'Gilroy-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.8);
}

.product-benifits-image {
    width: 100%;
    object-fit: cover;
}

.product-details-container {
    background: #FFFFFF;
    border: 1px solid #519BF6;
    border-radius: 15px;
    min-height: 266px;
    transition: all 0.3s;
}

.product-details-container:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-size: cover;
}

.product-details-container h3 {
    font-family: 'Gilroy-Medium';
    font-weight: 400;
    font-size: 32px;
    line-height: 31px;
    margin-bottom: 25px;
    margin-top: 10px;
}

.product-details-table {
    font-family: 'Gilroy-Medium';
    font-size: 20px;
    line-height: 24px;
    width: 100%;
}

.product-details-table tr {
    height: 37px;
}

.product-detail-table-image {
    width: 20px;
    margin-right: 4px;
}

.product-detail-table-value {
    font-family: 'Gilroy-Bold';
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    text-align: right;
}

.payoff-details {
    padding: 16px 50px;
    font-size: 20px;
    line-height: 24.26px;
}

.product-details-1:hover {
    background-image: url('../../images/product-details-bg.webp');
}

.token-details:hover {
    background-image: url('../../images/token-details-bg.webp');
}

.payoff-details:hover {
    background-image: url('../../images/payoff-bg.webp');
}

.payoff-details-subheading {
    margin-bottom: 26px;
}

.payoff-details h3 {
    margin-bottom: 20px;
}

.payoff-details-p {
    margin-bottom: 24px;
}

.divider-dash {
    border: 1px dashed #000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.risk-disclosure-content ul {
    padding-left: 20px;
    list-style: none;
}