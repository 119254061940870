.currency-dropdown {
    position: relative;
    width: 150px;
}

.dropdown-selected {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 10px;
    border: 2px solid rgba(255, 255, 255, 0.4);
    border-left: none;
    background-color: #3D7FE1;
    cursor: pointer;
}

.dropdown-icon {
    margin-right: 10px;
    width: 26px;
    height: 26px;
    filter: drop-shadow(1px 1px 0px rgba(0, 0, 0, 0.25));
}

.dropdown-text {
    flex-grow: 1;
    font-family: 'Gilroy-Bold';
    font-weight: 400;
    font-size: 18px;
    line-height: 0px;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.25);
}

.dropdown-arrow {
    margin-left: auto;
    font-size: 12px;
}

.dropdown-options {
    width: 152px;
    position: absolute;
    top: 100%;
    left: -2px;
    right: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    border: 2px solid rgba(255, 255, 255, 0.4);
    border-top: none;
    background-color: #3D7FE1;
    z-index: 1000;
}

.dropdown-option {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.dropdown-option:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #3D7FE1;
}