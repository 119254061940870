.sticky-top {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 1020;
}

.navbar-fintank {
	height: 85px;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), #519BF6;
	border-bottom: 1px solid rgba(255, 255, 255, 0.5);
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	backdrop-filter: blur(2px);
}

.nav-link {
	color: white;
	font-size: 23px;
	font-weight: 800;
	text-decoration: none;
	font-family: 'Inter';
	font-weight: 800;
	font-size: 23px;
	line-height: 31px;
	vertical-align: middle;
}

.navbar-fintank-container {
	height: 85px;
	line-height: 85px;
}

.nav-link-margin {
	margin-right: 90px;
}

header .avatar {
	align-items: center;
	display: inline-flex;
	vertical-align: middle;
}

header .metamask {
	height: 32px;
	margin-right: 20px;
	width: 33px;
}

header .avatar .link {
	color: #ffffff;
	font-size: 19px;
	font-weight: 700;
	line-height: 24px;
	text-decoration: none;
}