@font-face {
	font-family: 'Gilroy-Bold';
	font-style: normal;
	font-weight: normal;
	src: local('Gilroy-Bold'), url('./fonts/Gilroy-Bold.woff') format('woff');
}

@font-face {
	font-family: 'Gilroy-Heavy';
	font-style: normal;
	font-weight: normal;
	src: local('Gilroy-Heavy'), url('./fonts/Gilroy-Heavy.woff') format('woff');
}

@font-face {
	font-family: 'Gilroy-Light';
	font-style: normal;
	font-weight: normal;
	src: local('Gilroy-Light'), url('./fonts/Gilroy-Light.woff') format('woff');
}

@font-face {
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: normal;
	src: local('Gilroy-Medium'), url('./fonts/Gilroy-Medium.woff') format('woff');
}

@font-face {
	font-family: 'Gilroy-Regular';
	font-style: normal;
	font-weight: normal;
	src: local('Gilroy-Regular'), url('./fonts/Gilroy-Regular.woff') format('woff');
}

:root {
	--accent-color-1: #519BF6;
	--accent-color-2: #FCD12E;
}

body {
	background-color: #F3F4F4;
	font-family: 'Gilroy-Regular';
}

.btn-fintank-primary {
	color: black;
	font-size: 18px;
	width: 160px;
	height: 44px;
	background: linear-gradient(0deg, var(--accent-color-2), var(--accent-color-2)), radial-gradient(50% 68.47% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.05) 100%), #33D3FF;
	border: 1px solid #FFFFFF;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 6px;
}

.btn-fintank-primary-inactive {
	opacity: 0.5;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

a {
	text-decoration: none;
}