.fintank-footer {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(0deg, #519BF6, #519BF6);
	color: white;
	overflow: hidden;
}

.fintank-footer a {
	color: white;
	text-decoration: none;
}

.footer-links {
	width: 440px;
	font-family: 'Open Sans';

	h5 {
		font-weight: bold;
	}

	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
	}
}

.footer-social {
	font-family: 'Open Sans';

	h5 {
		font-weight: bold;
	}
}

.social-links a {
	font-size: 22px;
	width: 22px;
	display: inline-block;
}

.copy-right {
	font-size: 18px;
	text-align: center;
}