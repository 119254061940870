.porfolio-heading {
  background-image: url(../../images/portfolio-header-bg.png);
  background-size: initial;
  background-position: center -186px;
  color: #FFFFFF;
  height: 328px;

  h1 {
    font-family: 'Gilroy-Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 59px;
  }
}

.porfolio-heading span.address {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
}

.balance-container {
  margin-top: 60px;
}

.balance-title {
  font-family: 'Gilroy-Medium';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 31px;
  margin-bottom: 36px;
}

.balance-value {
  font-family: 'Gilroy-Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 31px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.porfolio-subheading {
  font-family: 'Gilroy-Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 45px;
  background: linear-gradient(180deg, #519BF6 0%, #0062DB 100%), linear-gradient(0deg, #519BF6, #519BF6), #0432FF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.investment-summary-card {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.12);
  background: #FFFFFF;
  border-radius: 15px 15px 0px 0px;
}

.investment-summary-card-header {
  padding: 24px 50px 0px 50px;
  height: 110px;
  background: linear-gradient(226.97deg, #519BF6 14.45%, #0062DB 139.42%);
  border-radius: 15px 15px 0px 0px;
  color: #FFFFFF;

  h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 31px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-top: 15px;
  }
}

.card-header-balance-value {
  font-family: 'Gilroy-Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 31px;
  margin-top: 10px;
}

.apy-container {
  margin: 40px 80px;
}

.table-container {
  margin: 0px 80px;
}

.card-apy {
  margin-left: 30px;
}

.card-amount {
  font-family: 'Gilroy-Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 31px;
  text-transform: capitalize;
  color: #000000;
  margin-top: 10px;
}

.card-apy {
  padding: 10px;
  background: #519BF6;
  border-radius: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 31px;
  text-transform: capitalize;
  color: var(--accent-color-2);
}

.investment-table {
  width: 100%;
}

.investment-table tr {
  height: 40px;
}

.investment-table-item {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 31px;
  text-transform: capitalize;
  color: #000000;
}

.investment-table-value {
  font-family: 'Gilroy-Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 31px;
  text-transform: capitalize;
  color: #000000;
  text-align: right;
}

.card-divider {
  border: 1px solid #000;
  opacity: 1;
}

.action-row {
  font-family: 'Gilroy-Medium';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 31px;
  color: #000000;
  padding: 0 80px;
  height: 49px;
  line-height: 49px;
}

.action-row:hover {
  background: #E5E5E5;
}

.action-button {
  margin: 0 auto;
  width: 135px;
  height: 39px;
  line-height: normal;
  background: radial-gradient(50% 68.47% at 50% 50%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%), #FCD12E;
  border: 1px solid #FFCC4D;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

.action-button.disabled {
  opacity: 0.5;
}