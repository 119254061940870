.products {
    border-bottom: 28px solid #519BF6;
    background-color: #DEE3EF;
    background-image: url(../../images/products-bg.png);
    background-size: initial;
    background-position: right;
    background-repeat: no-repeat;
}

.products-container {
    height: 621px;
    color: #FFFFFF;
}

.products-container-inner {
    width: 739px;
}

.products-subtitle {
    font-family: 'Gilroy-Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 35px;
    color: #000000;
}

.products-description {
    width: 758.52px;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    color: #000000;
}

.total-value-locked {
    font-family: 'Gilroy-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    color: #000000;
}

.total-value-number {
    font-family: 'Gilroy-Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 91px;
    text-transform: uppercase;
    color: #519BF6;
}