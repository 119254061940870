.divider {
  height: 28px;
  background: linear-gradient(180deg, #519BF6 0%, rgba(255, 255, 255, 0) 149.62%);
}

.products-title,
.vaults-title {
  font-family: 'Gilroy-Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 59px;
  text-transform: uppercase;
  background: linear-gradient(180deg, #519BF6 0%, #0062DB 100%), linear-gradient(0deg, #519BF6, #519BF6), #0432FF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
