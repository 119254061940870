.vaults {
    margin-bottom: 150px;
}

.vault-container {
    background-color: #FFF;
    border: 1px solid #519BF6;
    border-radius: 0px 0px 15px 15px;
}

.vault-body.active:hover {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #519BF6 178.96%), #FFFFFF;
    border-radius: 0px 0px 15px 15px;
}

.vault-body.inactive {
    opacity: 0.5;
}

.vault-header-container {
    height: 92px;
    background: url(../../images/vaults-bg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #FFFFFF;
    text-align: center;
    position: relative;
}

.vault-header-container h3 {
    height: 35px;
    font-family: 'Gilroy-Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 28.4474px;
    line-height: 35px;
    -webkit-text-stroke: 1px #519BF6;
    /* Position this to center of vault-header-container */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.vault-subheader {
    font-family: 'Gilroy-Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 39.8264px;
    line-height: 53px;
    color: #519BF6;
}

.vault-description {
    font-family: 'Gilroy-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 25.6027px;
    line-height: 31px;
    color: #000000;
}

.btn-fintank-vault-primary {
    height: 44px;
    width: 463px;
    display: block;
    background: radial-gradient(50% 68.47% at 50% 50%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%), #FCD12E;
    border: 1px solid #FFCC4D;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    font-family: 'Gilroy-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #000000;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.05);
}

table.vault-intro {
    width: 100%;
}

table.vault-intro tr {
    height: 48px;
}

td.vault-intro-item {
    height: 28px;
    font-family: 'Gilroy-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 22.7579px;
    line-height: 27px;
    color: #1E1E1E;
}

td.vault-intro-value {
    height: 30px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 22.7579px;
    line-height: 30px;
    text-align: right;
    color: #000000;
}