.banner {
    background-image: url(../../images/banner-bg-1.png);
    background-position: bottom;
    background-size: cover;
}

.banner-bg-container {
    position: relative;
}

.banner-star {
    position: absolute;
}

#banner-star-1 {
    top: 40px;
    right: 300px;
}

#banner-star-2 {
    top: 210px;
    left: 300px;
}

.banner-logo {
    mix-blend-mode: hard-light;
}

.banner-container {
    height: 653px;
    background-image: url(../../images/banner-bg-2.png), url(../../images/banner-bg-ellipse.png);
    background-size: initial;
    background-position: center;
    background-repeat: no-repeat;
    color: #FFFFFF;
}

.banner-subtitle {
    font-family: 'Gilroy-Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 62px;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.banner-description {
    width: 930px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #E8E8EA;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}